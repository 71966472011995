import defaultEnvironment from './env.data';
import { EnvironmentInterface } from './env.model';

export const confForEnv = (
  env: 'beta' | 'dev1' | 'dev2' | 'dev3' | 'dev4' | 'dev5' | 'dev6' | 'dev7' | 'dev8' | 'local',
  data: Partial<EnvironmentInterface> = {},
) => {
  const domain = `${env}.sembot.ovh`;
  const backend = `https://api-${domain}`;

  const confForEnv: EnvironmentInterface = {
    ...defaultEnvironment,
    ...{
      apiUrl: `${backend}/api/v1`,
      dataUrl: backend,
      domain,
    },
    ...data,
  };

  return confForEnv;
};
